@keyframes toLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes toRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes imageOpacity {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  75% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}
.animate_to-left {
  transform: translateX(100%);
  opacity: 0;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s;
}
.animate_to-left.is-active {
  opacity: 1;
  transform: translateX(0);
}

.animate_to-right {
  transform: translateX(-100%);
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s;
  opacity: 0;
}
.animate_to-right.is-active {
  opacity: 1;
  transform: translateX(0);
}

.animate_fadeUp {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity cubic-bezier(0.645, 0.045, 0.355, 1) 0.8s;
}
.animate_fadeUp.is-active {
  opacity: 1;
  transform: translateY(0);
}

.animate_fadeUp2 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, -webkit-transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
}
.animate_fadeUp2.is-active {
  opacity: 1;
  transform: translateY(0);
}

.animate_fadeYItem {
  transform: translateY(10px);
  transition: opacity cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, -webkit-transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
  opacity: 0;
}
.animate_fadeYItem.is-active {
  opacity: 1;
  transform: translateY(0);
}
.animate_fadeYItem:nth-child(2) {
  transition-delay: 0.2s;
}
.animate_fadeYItem:nth-child(3) {
  transition-delay: 0.4s;
}
.animate_fadeYItem:nth-child(4) {
  transition-delay: 0.6s;
}
.animate_fadeYItem:nth-child(5) {
  transition-delay: 0.8s;
}
.animate_fadeYItem:nth-child(6) {
  transition-delay: 1s;
}

.animate_fadeXItem {
  transform: translateX(10px);
  transition: opacity cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, -webkit-transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
  opacity: 0;
}
.animate_fadeXItem.is-active {
  opacity: 1;
  transform: translateX(0);
}
.animate_fadeXItem:nth-child(2) {
  transition-delay: 0.2s;
}
.animate_fadeXItem:nth-child(3) {
  transition-delay: 0.4s;
}
.animate_fadeXItem:nth-child(4) {
  transition-delay: 0.6s;
}
.animate_fadeXItem:nth-child(5) {
  transition-delay: 0.8s;
}

.animate_ImgBgSlide {
  overflow: hidden;
  position: relative;
}
.animate_ImgBgSlide img {
  position: relative;
  z-index: 1;
  opacity: 0;
}
.animate_ImgBgSlide::before {
  transform: translateX(-100%);
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(199, 198, 185, 0.7);
}
.animate_ImgBgSlide.is-active::before {
  transform: translateX(100%);
}
.animate_ImgBgSlide.is-active img {
  opacity: 1;
  animation: imageOpacity cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s;
}

.animate_ImgSlide {
  overflow: hidden;
  position: relative;
  transform: translateX(-100%);
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s;
}
.animate_ImgSlide.is-active {
  transform: translateX(0);
}

.delay-2 {
  transition-delay: 0.2s;
}

.delay-5 {
  transition-delay: 0.5s;
}
